<template>
  <div class="bg-zinc-100 text-black antialiased dark:bg-zinc-900 dark:text-gray-200">
    <div class="md:container mx-auto">
      <header :class="['flex items-center justify-between sm:hidden p-2 pl-4', { 'space-x-4': route.name === 'busca'}]">
        <div class="max-w-24">
          <HeaderLogo />
        </div>

        <div v-if="route.name === 'busca'" class="flex-1">
          <EventSearch :change-url-query="true" :change-url-query-debounced="true" :autofocus="false"></EventSearch>
        </div>
        <CityCurrent v-else @click.stop.prevent="openCitySelector" />

        <div class="flex items-center space-x-4">
          <HeaderProfileSwitch :mobile="true" v-if="route.name !== 'busca'" />
          <div class="p-3" @click.stop.prevent="openMobileMenu">
            <icon-menu />
          </div>
        </div>

        <!-- <HeaderProfileSwitch /> -->
      </header>
      <header
        class="items-center p-2 hidden sm:flex"
      >
        <div class="block w-24">
          <HeaderLogo />
        </div>

        <div class="flex-1 flex space-x-8">
          <div class="flex-1 max-w-48 lg:max-w-64 xl:max-w-96">
            <EventSearch :change-url-query="true" :change-url-query-debounced="true" :autofocus="false"></EventSearch>
            <!-- @onQueryChanged="onSearchQueryChanged" -->
          </div>
          <CityCurrent @click.stop.prevent="openCitySelector" />
        </div>

        <div class="flex-1 justify-items-end">
          <nav class="flex space-x-6 items-center">
            <!-- Sobre / Blog -->
            <NuxtLink to="/como-funciona">
              Para produtores
            </NuxtLink>
            
            <a href="https://ajuda.pixta.me/?utm_source=site-header" target="_blank">
              Suporte
            </a>
  
            <HeaderProfileSwitch />
          </nav>
        </div>

      </header>

       <!--
          Flyout menu, show/hide based on flyout menu state.

          Entering: "transition ease-out duration-200"
            From: "opacity-0 translate-y-1"
            To: "opacity-100 translate-y-0"
          Leaving: "transition ease-in duration-150"
            From: "opacity-100 translate-y-0"
            To: "opacity-0 translate-y-1"
        -->
            <!--
            Background backdrop, show/hide based on modal state.
            Entering: ""
              From: ""
              To: ""
            Leaving: ""
              From: ""
              To: "opacity-0"
          -->
      <transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 z-20 transition-opacity" v-if="backdropOpened" aria-hidden="true"></div>
      </transition>
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        class="z-30 relative"
      >
        <div v-if="mobileMenuOpened">
          <div class="fixed top-12 left-1/2 z-30 flex w-screen max-w-max -translate-x-1/2 px-4" v-on-click-outside="closeMobileMenu">
            <div class="w-screen max-w-md flex-auto overflow-hidden rounded-xl bg-zinc-100 dark:bg-zinc-800 text-sm/6 shadow-lg ring-1 ring-gray-900/5">
              <div class="p-4">
                <div class="group relative flex gap-x-6 rounded-lg p-2 hover:bg-zinc-200 hover:dark:bg-zinc-900">
                  <div>
                    <NuxtLink to="/busca" class="">
                      Procurar Eventos
                      <span class="absolute inset-0"></span>
                    </NuxtLink>
                    <!-- <p class="mt-1 opacity-70">Procure por eventos, feiras, cursos</p> -->
                  </div>
                </div>
                <div class="group relative flex gap-x-6 rounded-lg p-2 hover:bg-zinc-200 hover:dark:bg-zinc-900">
                  <div>
                    <a href="https://ajuda.pixta.me/?utm_source=site-header" target="_blank" class="">
                      Central de Ajuda
                      <span class="absolute inset-0"></span>
                    </a>
                    <!-- <p class="mt-1 opacity-70">Ficou com alguma dúvida ou precisa de ajuda</p> -->
                  </div>
                </div>
                <div class="group relative flex gap-x-6 rounded-lg p-2 hover:bg-zinc-200 hover:dark:bg-zinc-900">
                  <div>
                    <NuxtLink to="/como-funciona" class="">
                      Para produtores
                      <span class="absolute inset-0"></span>
                    </NuxtLink>
                    <p class="mt-1 opacity-70">Entenda como a Pixta funciona e crie seu evento</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div v-if="citySelectorOpened">
        <div class="fixed left-1/2 z-30 flex w-screen max-w-max -translate-x-1/2 px-4" v-on-click-outside="closeCitySelector">
          <div class="w-screen max-w-md flex-auto overflow-hidden rounded-xl bg-zinc-100 dark:bg-zinc-800 text-sm/6 shadow-lg ring-1 ring-gray-900/5">
            <CitySelectorModal @onCitySelected="onChangeCity" />
          </div>
        </div>
      </div>


      <div class="pb-24 relative z-0">
        <slot />

        <footer v-if="shouldShowFooter" class="mt-64 mb-0">
          <div class="px-2">
            <div class="grid grid-cols-1 space-y-8 md:grid-cols-4">
              <div>
                <div class="text-center sm:text-left">
                  <img class="mx-auto sm:mx-0 w-28 dark:invert" src="/badgenew.png" alt="Pixta Badge" width="32" height="32" />
                  <div class="">
                    <p class="text-sm opacity-70">
                      A <strong>pixta</strong> é um produto independente de Curitiba, feito por quem ama música e tecnologia
                    </p>
                  </div>
                </div>
              </div>

              <div class="grid grid-rows-auto auto-rows-max space-y-2">
                <h5 class="font-medium">sobre</h5>

                <!-- <a href="sobre" class="opacity-70">
                  sobre a pixta
                </a> -->

                <NuxtLink class="opacity-70" to="/como-funciona">
                  como funciona
                </NuxtLink>

                <a href="https://drive.google.com/drive/folders/1nvRZRzucf6HeM_wvTKMNsAxe1bEVpX9O" class="opacity-70" target="_blank">
                  marcas & logos
                </a>
                <a href="https://t.co/WJAH43wyq2" class="opacity-70" target="_blank">
                  manual de marca
                </a>

                <!-- <NuxtLink to="sobre" class="opacity-70">
                  artistas
                </NuxtLink> -->

                <NuxtLink class="opacity-70" to="/arquivo">
                  eventos
                </NuxtLink>

                <!-- <NuxtLink to="sobre" class="opacity-70">
                  trabalhe conosco
                </NuxtLink> -->
              </div>

              <div class="grid grid-rows-auto auto-rows-max space-y-2">
                <h5 class="font-medium">
                  cidades
                </h5>

                <NuxtLink to="/eventos/curitiba" class="opacity-70">
                  curitiba
                </NuxtLink>

                <NuxtLink to="/eventos/sao-paulo" class="opacity-70">
                  são paulo
                </NuxtLink>

                <!-- aracatuba -->
                <NuxtLink to="/eventos/aracatuba" class="opacity-70">
                  araçatuba
                </NuxtLink>

                <NuxtLink to="/cidades" class="opacity-70">
                  todas
                </NuxtLink>
              </div>

              <div class="grid grid-rows-auto auto-rows-max space-y-2">
                <h5 class="font-medium">
                  suporte
                </h5>

                <a href="https://ajuda.pixta.me/?utm_source=site-footer" class="opacity-70" target="_blank">
                  central de ajuda
                </a>

                <a href="https://wa.me/5541992700420" class="opacity-70" target="_blank">
                  fale no whatsapp
                </a>

                <NuxtLink  class="opacity-70" to="/como-funciona">
                  crie seu evento
                </NuxtLink>

                <NuxtLink v-if="false" class="opacity-70" to="/central-de-ajuda">
                  central de ajuda
                </NuxtLink>

                <!-- <a href="suporte" class="opacity-70">
                  fale conosco
                </a> -->

                <!-- <a href="suporte" class="opacity-70">
                  Meia Entrada
                </a> -->

                <h5 class="font-medium">
                  redes sociais
                </h5>

                <div class="flex space-x-4">
                  <a href="https://www.instagram.com/pixta.me/?utm_source=sitefooter" class="opacity-70">
                    <!-- instagram -->
                    <svg class="w-6 h-auto" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
                  </a>

                  <a href="https://www.x.com/pixtame/" class="opacity-70">
                    <!-- twitter -->
                    <svg class="w-6 h-auto" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter</title><path d="M21.543 7.104c.015.211.015.423.015.636 0 6.507-4.954 14.01-14.01 14.01v-.003A13.94 13.94 0 0 1 0 19.539a9.88 9.88 0 0 0 7.287-2.041 4.93 4.93 0 0 1-4.6-3.42 4.916 4.916 0 0 0 2.223-.084A4.926 4.926 0 0 1 .96 9.167v-.062a4.887 4.887 0 0 0 2.235.616A4.928 4.928 0 0 1 1.67 3.148 13.98 13.98 0 0 0 11.82 8.292a4.929 4.929 0 0 1 8.39-4.49 9.868 9.868 0 0 0 3.128-1.196 4.941 4.941 0 0 1-2.165 2.724A9.828 9.828 0 0 0 24 4.555a10.019 10.019 0 0 1-2.457 2.549z"/></svg>
                  </a>

                  <a href="https://www.youtube.com/@pixta.me_" class="opacity-70">
                    <!-- pixta.me -->
                    <svg class="w-6 h-auto" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube</title><path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>
                  </a>
                </div>

              </div>
            </div>

            <div class="flex justify-between items-center border-t dark:border-zinc-700 pt-4 mt-4">
              <div class="opacity-60">
                &copy; 2022-2024 Pixta Tecnologia Ltda 53.943.955/0001-49
              </div>
              <div>
                <!-- <a href="suporte" class="opacity-70">
                  Política de Privacidade
                </a>

                <a href="suporte" class="opacity-70">
                  Termos de Uso
                </a> -->
              </div>
            </div>
          </div>

        </footer>
      </div>
    </div>

    <div v-if="shouldShowBottonNav" class="fixed lg:hidden bottom-0 z-10 w-full block text-xs sm:text-base">
      <div id="admin-bottom-nav" class="bg-white text-xl flex justify-center text-black dark:bg-zinc-900 dark:text-gray-200 w-full overflow-hidden h-18">
      </div>
      <div class="bg-white text-black dark:bg-zinc-900 pb-0 dark:text-gray-200 w-full overflow-hidden h-18 bottom-main-nav">
        <transition
          enter-active-class="transition ease-out duration-200 transform"
          enter-from-class="scale-y-0 opacity-0"
          enter-to-class="transform-none translate-y-0 opacity-100"
          leave-active-class="transition ease-in duration-200 transform relative"
          leave-from-class="translate-y-8"
          leave-to-class="translate-y-0 scale-y-0 opacity-0">
          <nav class="flex justify-center items-end" v-if="isInsideMyEvents">
            <a class="flex flex-wrap justify-center items-center p-2" href="/meus_eventos">
              <icon-calendar class="block" />
              <span class="block w-full mt-1 text-center">eventos</span>
            </a>

            <NuxtLink v-if="false" class="flex flex-wrap justify-center items-center p-2" to="/carteira">
              <icon-wallet class="block" />
              <span class="block w-full mt-1 text-center">carteira</span>
            </NuxtLink>

            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/conta">
              <icon-user class="block" />
              <span class="block w-full mt-1 text-center">conta</span>
            </NuxtLink>
          </nav>

          <nav class="flex justify-center items-end" v-else>
            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/">
              <icon-home class="block" />
              <span class="block w-full mt-1 text-center">home</span>
            </NuxtLink>

            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/ingressos">
              <icon-ticket class="block" />
              <span class="block w-full mt-1 text-center">ingressos</span>
            </NuxtLink>


            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/conta">
              <icon-user class="block" />
              <span class="block w-full mt-1 text-center">conta</span>
            </NuxtLink>
          </nav>
        </transition>
      </div>
    </div>

  </div>
</template>

<script setup>
// import UserService from "@/services/user.service"
// import HeaderLogo from '@/components/HeaderLogo.vue'
// import { mapStores } from 'pinia'
import { useUserStore } from '@/store/user.js'
import { useMainStore } from '@/store/root.js'
// import { ClientOnly } from '@/components/ClientOnly.js'
import { vOnClickOutside } from '@vueuse/components'
const userStore = useUserStore()
const mainStore = useMainStore()
const router = useRouter()
const route = useRoute()

const citySelectorOpened = ref(false)
const mobileMenuOpened = ref(false)
const backdropOpened = ref(false)


const onChangeCity = async (city) => {
  await userStore.changeCity(city);
  router.push(`/eventos/${city.slug}`)
  closeCitySelector()
  // refresh()
}

import { useEventBus } from '@vueuse/core'

const bus = useEventBus('citySelectorModal')
function listener(event) {
  openCitySelector()
}
const unsubscribe = bus.on(listener)
onBeforeUnmount(unsubscribe)

import { storeToRefs } from 'pinia'
const { currentUser, loggedIn: isLoggedIn, isProducer, currentProducer } = storeToRefs(userStore)

const { isMenuOpen } = storeToRefs(mainStore)

const isInsideMyEvents = computed(() => {
  return route.path.toString().startsWith('/meus_eventos') ||
          route.path.toString().startsWith('/carteira') ||
          route.path.toString().startsWith('/producer_wizard')
})

const isInsideProducerWizard = computed(() => {
  return route.path.toString().startsWith('/producer_wizard')
})

// remove distractions
const shouldShowFooter = computed(() => {
  return !isInsideProducerWizard.value
})

// to remove distractions and make it work with avatar cropper on producer wizard
const shouldShowBottonNav = computed(() => {
  return !isInsideProducerWizard.value
})

const openCitySelector = () => {
  citySelectorOpened.value = true
  backdropOpened.value = true
}
const closeCitySelector = () => {
  citySelectorOpened.value = false
  backdropOpened.value = false
}
const openMobileMenu = () => {
  mobileMenuOpened.value = true
  backdropOpened.value = true
}
const closeMobileMenu = () => {
  mobileMenuOpened.value = false
  backdropOpened.value = false
}

watch(() => route.path, () => {
  mobileMenuOpened.value = false
  backdropOpened.value = false
})

useHead({
  htmlAttrs: {
    lang: 'pt-BR',
    class: 'bg-zinc-900 light:bg-white'
  }
})

</script>

<style lang="scss">
/* Common transition settings */
.transition-active {
  transition: all 0.4s ease;
}

/* Slide left/right transitions */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s;
}

.slide-enter-from {
  opacity: 0;
  transform: translateX(50px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}

/* Rotate transition */
.rotate-enter-active,
.rotate-leave-active {
  transition: all 0.4s;
}

.rotate-enter-from {
  opacity: 0;
  transform: rotate3d(1, 1, 1, 15deg);
}

.rotate-leave-to {
  opacity: 0;
  transform: rotate3d(1, 1, 1, 15deg);
}

/* Slim fade transition */
.slim-fade-enter-active,
.slim-fade-leave-active {
  transition: all 0.4s ease;
}

.slim-fade-enter,
.slim-fade-leave-to {
  opacity: 0;
  transform-origin: top;
  transform: translateY(100%);
}

/* New slide down with fade transition */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.25s;
}

.slide-down-enter-from,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* adds fade in out */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
