<template>
  <div>
    <div class="p-4 max-h-[50vh]">
      <div class="flex flex-col">
        <div class="block py-2">
          <h1 class="px-2 cursor-default text-xl">
            Selecione sua cidade
          </h1>
          <h2 class="px-2">
            Selecionado:
            <span v-if="currentCity?.id">{{ currentCity.name }}/{{ currentCity.uf }}</span>
            <span v-else>Todo o Brasil</span>
          </h2>
        </div>
        <div class="flex-1 grow overflow-y-auto overscroll-contain max-h-64 md:max-h-80">
          <div class="" v-for="uf in Object.keys(filteredCitiesByUF)" :key="uf">
            <h3 class="font-bold opacity-60 px-2">{{ uf }}</h3>
            <a v-for="city in filteredCitiesByUF[uf]" :key="`idx-${city.name}`"
              class="group block cursor-pointer rounded-lg p-2 hover:bg-zinc-200 hover:dark:bg-zinc-900"
              @click="selectCity(city)">
                {{ city.name }} <small>({{ city.count }})</small>
            </a>
          </div>
        </div>
      </div>

    </div>
    
  </div>
</template>

<script setup>

const filteredCitiesByUF = computed(() => {
  return cities.value.reduce((acc, city) => {
    if (city.name && city.uf) {
      if (!acc[city.uf]) {
        acc[city.uf] = [];
      }
      acc[city.uf].push(city);
    }
  return acc;
  }, {});
});

import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user.js'

const userStore = useUserStore()
const { loggedIn: isLoggedIn, currentCity } = storeToRefs(userStore)

const emit = defineEmits(['onCitySelected'])

const isCitySelectorModalOpen = ref(false);

const route = useRoute()
const router = useRouter()

const runtimeConfig = useRuntimeConfig()
const { baseApiUrl: baseUrl } = runtimeConfig.public

const { data: cities } = await useFetch(() => `${baseUrl}/api/cities.json`, { server: false })
// currentCity.value = cities.value?.find(city => city.slug === route.params.city)

watch(route, () => {
  isCitySelectorModalOpen.value = false;
});

const selectCity = async (city) => {
  userStore.changeCity(city);
  isCitySelectorModalOpen.value = false;
  emit('onCitySelected', city)
}

const openDialogButton = () => {
  isCitySelectorModalOpen.value = !isCitySelectorModalOpen.value
}

</script>
